<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-card
          title="Importiere Bewerber"
        >
          <v-card-title>Importiere Bewerber</v-card-title>
          <v-card-text>
            <div class="text-center" v-if="loading">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-form
              v-else
              ref="form"
              @submit.prevent="handleFormSubmit"
            >
              <v-row>
                <v-col cols="12" md="12">
                  <v-file-input
                    accept=".pdf"
                    placeholder="PDF wählen (Lebenslauf)"
                    ref="file"
                    v-on:change="onFileSelected"
                    id="formFile"
                    :rules="rules"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-btn color="primary" type="submit">
                    Absenden
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import axios from "axios";
import {ref, getCurrentInstance} from '@vue/composition-api'
import {required} from '@core/utils/validation'

export default {

  setup() {
    const loading = ref(false)
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 2000;
    const form = ref(null)
    const selectedFile = ref("")
    const rules = [
      value => !!value || 'Bitte wählen Sie eine Datei aus',
      value => !value || value.size < 20000000 || 'Die Datei darf maximal nur 20 MB groß sein.',
      value => !value || value.type == "application/pdf" || 'Bitte wählen Sie eine PDF-Datei aus.'
    ];

    const validate = () => {
      form.value.validate()
    }

    const onFileSelected = ((file)=>{
      try{
        selectedFile.value = file;
      }catch(e){
        loading.value = false;
        vm.$toastr.e(e.message,e.message);
      }
    });

    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()
      if(!isFormValid) return
      loading.value = true;
      const fd = new FormData();
      fd.append('aktion','vitaUploadCheckJoinvision');
      fd.append('lebenslauf',selectedFile.value,selectedFile.name);
      axios.post('/api/bewerber/', fd)
        .then((res) => {
          loading.value = false;
          if(res.data.valid === true){
            router.push({name:'bewerber'},()=>{
              vm.$toastr.s(res.data.message.text, res.data.message.title);
            });
          }
          else if(res.data.errors !== undefined){
            res.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
        })
        .catch((error) => {
          console.log(error);
          loading.value = false;
        })
    }

    return {
      handleFormSubmit,
      loading,
      form,
      selectedFile,
      onFileSelected,
      rules,
      validators:{
        required,
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
